var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "employee-new-modal" } },
    [
      _c(
        "vs-popup",
        {
          staticClass: "popup70",
          attrs: { title: _vm.title, active: _vm.localShow },
          on: {
            "update:active": function ($event) {
              _vm.localShow = $event
            },
          },
        },
        [
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-full w-full mt-0" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Instrutor"),
                ]),
                _c("v-select", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate.initial",
                      value: "required",
                      expression: "'required'",
                      modifiers: { initial: true },
                    },
                  ],
                  staticStyle: { "background-color": "white" },
                  attrs: {
                    reduce: (option) => option.value,
                    "data-vv-as": "Instrutor",
                    name: "employee_id",
                    options: _vm.employees,
                    placeholder: "Selecione",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n            Desculpe, nenhum resultado encontrado.\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.internalServiceOrder.employee_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.internalServiceOrder, "employee_id", $$v)
                    },
                    expression: "internalServiceOrder.employee_id",
                  },
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("employee_id"),
                        expression: "errors.has('employee_id')",
                      },
                    ],
                    staticClass: "text-danger text-sm",
                  },
                  [_vm._v("Campo Obrigatório.")]
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-1/6 w-full mt-0" },
              [
                _c("vs-input", {
                  staticClass: "w-full mt-0",
                  attrs: { type: "number", label: "Qtd." },
                  on: {
                    change: function ($event) {
                      return _vm.calculateUnity(1)
                    },
                  },
                  model: {
                    value: _vm.internalServiceOrder.quantity_1,
                    callback: function ($$v) {
                      _vm.$set(_vm.internalServiceOrder, "quantity_1", $$v)
                    },
                    expression: "internalServiceOrder.quantity_1",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/2 w-full mt-0" },
              [
                _c("vs-input", {
                  staticClass: "w-full mt-0",
                  attrs: { label: "Descrição." },
                  model: {
                    value: _vm.internalServiceOrder.description_1,
                    callback: function ($$v) {
                      _vm.$set(_vm.internalServiceOrder, "description_1", $$v)
                    },
                    expression: "internalServiceOrder.description_1",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/6 w-full mt-0" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Valor Un."),
                ]),
                _c("currency-input", {
                  staticClass:
                    "w-full vs-inputx vs-input--input normal hasValue",
                  attrs: {
                    currency: { prefix: "R$ " },
                    valueAsInteger: false,
                    distractionFree: false,
                    precision: 2,
                    autoDecimalMode: true,
                    valueRange: { min: 0 },
                    allowNegative: false,
                  },
                  on: {
                    input: function ($event) {
                      return _vm.calculateUnity(1)
                    },
                  },
                  model: {
                    value: _vm.internalServiceOrder.unit_value_1,
                    callback: function ($$v) {
                      _vm.$set(_vm.internalServiceOrder, "unit_value_1", $$v)
                    },
                    expression: "internalServiceOrder.unit_value_1",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/6 w-full mt-0" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Total"),
                ]),
                _c("currency-input", {
                  staticClass:
                    "w-full vs-inputx vs-input--input normal hasValue",
                  attrs: {
                    disabled: "",
                    currency: { prefix: "R$ " },
                    valueAsInteger: false,
                    distractionFree: false,
                    precision: 2,
                    autoDecimalMode: true,
                    valueRange: { min: 0 },
                    allowNegative: false,
                  },
                  model: {
                    value: _vm.internalServiceOrder.unit_total_1,
                    callback: function ($$v) {
                      _vm.$set(_vm.internalServiceOrder, "unit_total_1", $$v)
                    },
                    expression: "internalServiceOrder.unit_total_1",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-1/6 w-full mt-0" },
              [
                _c("vs-input", {
                  staticClass: "w-full mt-0",
                  attrs: { type: "number", label: "" },
                  on: {
                    change: function ($event) {
                      return _vm.calculateUnity(2)
                    },
                  },
                  model: {
                    value: _vm.internalServiceOrder.quantity_2,
                    callback: function ($$v) {
                      _vm.$set(_vm.internalServiceOrder, "quantity_2", $$v)
                    },
                    expression: "internalServiceOrder.quantity_2",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/2 w-full mt-0" },
              [
                _c("vs-input", {
                  staticClass: "w-full mt-0",
                  attrs: { label: "" },
                  model: {
                    value: _vm.internalServiceOrder.description_2,
                    callback: function ($$v) {
                      _vm.$set(_vm.internalServiceOrder, "description_2", $$v)
                    },
                    expression: "internalServiceOrder.description_2",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/6 w-full mt-0" },
              [
                _c("currency-input", {
                  staticClass:
                    "w-full vs-inputx vs-input--input normal hasValue",
                  attrs: {
                    currency: { prefix: "R$ " },
                    valueAsInteger: false,
                    distractionFree: false,
                    precision: 2,
                    autoDecimalMode: true,
                    valueRange: { min: 0 },
                    allowNegative: false,
                  },
                  on: {
                    input: function ($event) {
                      return _vm.calculateUnity(2)
                    },
                  },
                  model: {
                    value: _vm.internalServiceOrder.unit_value_2,
                    callback: function ($$v) {
                      _vm.$set(_vm.internalServiceOrder, "unit_value_2", $$v)
                    },
                    expression: "internalServiceOrder.unit_value_2",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/6 w-full mt-0" },
              [
                _c("currency-input", {
                  staticClass:
                    "w-full vs-inputx vs-input--input normal hasValue",
                  attrs: {
                    disabled: "",
                    currency: { prefix: "R$ " },
                    valueAsInteger: false,
                    distractionFree: false,
                    precision: 2,
                    autoDecimalMode: true,
                    valueRange: { min: 0 },
                    allowNegative: false,
                  },
                  model: {
                    value: _vm.internalServiceOrder.unit_total_2,
                    callback: function ($$v) {
                      _vm.$set(_vm.internalServiceOrder, "unit_total_2", $$v)
                    },
                    expression: "internalServiceOrder.unit_total_2",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-1/6 w-full mt-0" },
              [
                _c("vs-input", {
                  staticClass: "w-full mt-0",
                  attrs: { type: "number", label: "" },
                  on: {
                    change: function ($event) {
                      return _vm.calculateUnity(3)
                    },
                  },
                  model: {
                    value: _vm.internalServiceOrder.quantity_3,
                    callback: function ($$v) {
                      _vm.$set(_vm.internalServiceOrder, "quantity_3", $$v)
                    },
                    expression: "internalServiceOrder.quantity_3",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/2 w-full mt-0" },
              [
                _c("vs-input", {
                  staticClass: "w-full mt-0",
                  attrs: { label: "" },
                  model: {
                    value: _vm.internalServiceOrder.description_3,
                    callback: function ($$v) {
                      _vm.$set(_vm.internalServiceOrder, "description_3", $$v)
                    },
                    expression: "internalServiceOrder.description_3",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/6 w-full mt-0" },
              [
                _c("currency-input", {
                  staticClass:
                    "w-full vs-inputx vs-input--input normal hasValue",
                  attrs: {
                    currency: { prefix: "R$ " },
                    valueAsInteger: false,
                    distractionFree: false,
                    precision: 2,
                    autoDecimalMode: true,
                    valueRange: { min: 0 },
                    allowNegative: false,
                  },
                  on: {
                    input: function ($event) {
                      return _vm.calculateUnity(3)
                    },
                  },
                  model: {
                    value: _vm.internalServiceOrder.unit_value_3,
                    callback: function ($$v) {
                      _vm.$set(_vm.internalServiceOrder, "unit_value_3", $$v)
                    },
                    expression: "internalServiceOrder.unit_value_3",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/6 w-full mt-0" },
              [
                _c("currency-input", {
                  staticClass:
                    "w-full vs-inputx vs-input--input normal hasValue",
                  attrs: {
                    disabled: "",
                    currency: { prefix: "R$ " },
                    valueAsInteger: false,
                    distractionFree: false,
                    precision: 2,
                    autoDecimalMode: true,
                    valueRange: { min: 0 },
                    allowNegative: false,
                  },
                  model: {
                    value: _vm.internalServiceOrder.unit_total_3,
                    callback: function ($$v) {
                      _vm.$set(_vm.internalServiceOrder, "unit_total_3", $$v)
                    },
                    expression: "internalServiceOrder.unit_total_3",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-1/6 w-full mt-0" },
              [
                _c("vs-input", {
                  staticClass: "w-full mt-0",
                  attrs: { type: "number", label: "" },
                  on: {
                    change: function ($event) {
                      return _vm.calculateUnity(4)
                    },
                  },
                  model: {
                    value: _vm.internalServiceOrder.quantity_4,
                    callback: function ($$v) {
                      _vm.$set(_vm.internalServiceOrder, "quantity_4", $$v)
                    },
                    expression: "internalServiceOrder.quantity_4",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/2 w-full mt-0" },
              [
                _c("vs-input", {
                  staticClass: "w-full mt-0",
                  attrs: { label: "" },
                  model: {
                    value: _vm.internalServiceOrder.description_4,
                    callback: function ($$v) {
                      _vm.$set(_vm.internalServiceOrder, "description_4", $$v)
                    },
                    expression: "internalServiceOrder.description_4",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/6 w-full mt-0" },
              [
                _c("currency-input", {
                  staticClass:
                    "w-full vs-inputx vs-input--input normal hasValue",
                  attrs: {
                    currency: { prefix: "R$ " },
                    valueAsInteger: false,
                    distractionFree: false,
                    precision: 2,
                    autoDecimalMode: true,
                    valueRange: { min: 0 },
                    allowNegative: false,
                  },
                  on: {
                    input: function ($event) {
                      return _vm.calculateUnity(4)
                    },
                  },
                  model: {
                    value: _vm.internalServiceOrder.unit_value_4,
                    callback: function ($$v) {
                      _vm.$set(_vm.internalServiceOrder, "unit_value_4", $$v)
                    },
                    expression: "internalServiceOrder.unit_value_4",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/6 w-full mt-0" },
              [
                _c("currency-input", {
                  staticClass:
                    "w-full vs-inputx vs-input--input normal hasValue",
                  attrs: {
                    disabled: "",
                    currency: { prefix: "R$ " },
                    valueAsInteger: false,
                    distractionFree: false,
                    precision: 2,
                    autoDecimalMode: true,
                    valueRange: { min: 0 },
                    allowNegative: false,
                  },
                  model: {
                    value: _vm.internalServiceOrder.unit_total_4,
                    callback: function ($$v) {
                      _vm.$set(_vm.internalServiceOrder, "unit_total_4", $$v)
                    },
                    expression: "internalServiceOrder.unit_total_4",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-1/6 w-full mt-0" },
              [
                _c("vs-input", {
                  staticClass: "w-full mt-0",
                  attrs: { type: "number", label: "" },
                  on: {
                    change: function ($event) {
                      return _vm.calculateUnity(5)
                    },
                  },
                  model: {
                    value: _vm.internalServiceOrder.quantity_5,
                    callback: function ($$v) {
                      _vm.$set(_vm.internalServiceOrder, "quantity_5", $$v)
                    },
                    expression: "internalServiceOrder.quantity_5",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/2 w-full mt-0" },
              [
                _c("vs-input", {
                  staticClass: "w-full mt-0",
                  attrs: { label: "" },
                  model: {
                    value: _vm.internalServiceOrder.description_5,
                    callback: function ($$v) {
                      _vm.$set(_vm.internalServiceOrder, "description_5", $$v)
                    },
                    expression: "internalServiceOrder.description_5",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/6 w-full mt-0" },
              [
                _c("currency-input", {
                  staticClass:
                    "w-full vs-inputx vs-input--input normal hasValue",
                  attrs: {
                    currency: { prefix: "R$ " },
                    valueAsInteger: false,
                    distractionFree: false,
                    precision: 2,
                    autoDecimalMode: true,
                    valueRange: { min: 0 },
                    allowNegative: false,
                  },
                  on: {
                    input: function ($event) {
                      return _vm.calculateUnity(5)
                    },
                  },
                  model: {
                    value: _vm.internalServiceOrder.unit_value_5,
                    callback: function ($$v) {
                      _vm.$set(_vm.internalServiceOrder, "unit_value_5", $$v)
                    },
                    expression: "internalServiceOrder.unit_value_5",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/6 w-full mt-0" },
              [
                _c("currency-input", {
                  staticClass:
                    "w-full vs-inputx vs-input--input normal hasValue",
                  attrs: {
                    disabled: "",
                    currency: { prefix: "R$ " },
                    valueAsInteger: false,
                    distractionFree: false,
                    precision: 2,
                    autoDecimalMode: true,
                    valueRange: { min: 0 },
                    allowNegative: false,
                  },
                  model: {
                    value: _vm.internalServiceOrder.unit_total_5,
                    callback: function ($$v) {
                      _vm.$set(_vm.internalServiceOrder, "unit_total_5", $$v)
                    },
                    expression: "internalServiceOrder.unit_total_5",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "vx-row" }, [
            _c("div", { staticClass: "vx-col md:w-1/6 w-full mt-0" }),
            _c("div", { staticClass: "vx-col md:w-1/2 w-full mt-0" }),
            _c("div", { staticClass: "vx-col md:w-1/6 w-full mt-0" }),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/6 w-full mt-0" },
              [
                _c("currency-input", {
                  staticClass:
                    "w-full vs-inputx vs-input--input normal hasValue",
                  attrs: {
                    disabled: "",
                    currency: { prefix: "R$ " },
                    valueAsInteger: false,
                    distractionFree: false,
                    precision: 2,
                    autoDecimalMode: true,
                    valueRange: { min: 0 },
                    allowNegative: false,
                  },
                  model: {
                    value: _vm.internalServiceOrder.total,
                    callback: function ($$v) {
                      _vm.$set(_vm.internalServiceOrder, "total", $$v)
                    },
                    expression: "internalServiceOrder.total",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("vs-divider"),
          _c("div", { staticClass: "vx-row" }, [
            _c("div", { staticClass: "vx-col w-full" }, [
              _c(
                "div",
                { staticClass: "mt-4 flex flex-wrap items-center justify-end" },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "ml-auto mt-2",
                      attrs: { disabled: !_vm.validateForm },
                      on: { click: _vm.save },
                    },
                    [_vm._v("Cadastrar")]
                  ),
                  _c(
                    "vs-button",
                    {
                      staticClass: "ml-4 mt-2",
                      attrs: { type: "border", color: "secondary" },
                      on: { click: _vm.cancel },
                    },
                    [_vm._v("Cancelar")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }