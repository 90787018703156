<template>
  <div id="employee-new-modal">
    <vs-popup class="popup70" :title="title" :active.sync="localShow">

      <div class="vx-row">
        <div class="vx-col md:w-full w-full mt-0">
          <label class="vs-input--label">Instrutor</label>
          <v-select v-model="internalServiceOrder.employee_id" :reduce="option => option.value"
            data-vv-as="Instrutor" v-validate.initial="'required'" name="employee_id"
            :options="employees" placeholder="Selecione" style="background-color: white;" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Desculpe, nenhum resultado encontrado.
            </template>
          </v-select>
          <span class="text-danger text-sm" v-show="errors.has('employee_id')">Campo Obrigatório.</span>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col md:w-1/6 w-full mt-0">
          <vs-input type="number" @change="calculateUnity(1)" class="w-full mt-0" label="Qtd." v-model="internalServiceOrder.quantity_1" />
        </div>
        <div class="vx-col md:w-1/2 w-full mt-0">
          <vs-input class="w-full mt-0" label="Descrição." v-model="internalServiceOrder.description_1" />
        </div>
        <div class="vx-col md:w-1/6 w-full mt-0">
          <label class="vs-input--label">Valor Un.</label>
          <currency-input class="w-full vs-inputx vs-input--input normal hasValue"
            v-model="internalServiceOrder.unit_value_1"
            @input="calculateUnity(1)"
            :currency="{prefix: 'R$ '}"
            :valueAsInteger="false"
            :distractionFree="false"
            :precision="2"
            :autoDecimalMode="true"
            :valueRange="{ min: 0 }"
            :allowNegative="false" />
        </div>
        <div class="vx-col md:w-1/6 w-full mt-0">
          <label class="vs-input--label">Total</label>
          <currency-input class="w-full vs-inputx vs-input--input normal hasValue"
            v-model="internalServiceOrder.unit_total_1" disabled
            :currency="{prefix: 'R$ '}"
            :valueAsInteger="false"
            :distractionFree="false"
            :precision="2"
            :autoDecimalMode="true"
            :valueRange="{ min: 0 }"
            :allowNegative="false" />
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col md:w-1/6 w-full mt-0">
          <vs-input type="number" @change="calculateUnity(2)" class="w-full mt-0" label="" v-model="internalServiceOrder.quantity_2" />
        </div>
        <div class="vx-col md:w-1/2 w-full mt-0">
          <vs-input class="w-full mt-0" label="" v-model="internalServiceOrder.description_2" />
        </div>
        <div class="vx-col md:w-1/6 w-full mt-0">
          <currency-input class="w-full vs-inputx vs-input--input normal hasValue"
            v-model="internalServiceOrder.unit_value_2"
            @input="calculateUnity(2)"
            :currency="{prefix: 'R$ '}"
            :valueAsInteger="false"
            :distractionFree="false"
            :precision="2"
            :autoDecimalMode="true"
            :valueRange="{ min: 0 }"
            :allowNegative="false" />
        </div>
        <div class="vx-col md:w-1/6 w-full mt-0">
          <currency-input class="w-full vs-inputx vs-input--input normal hasValue"
            v-model="internalServiceOrder.unit_total_2" disabled
            :currency="{prefix: 'R$ '}"
            :valueAsInteger="false"
            :distractionFree="false"
            :precision="2"
            :autoDecimalMode="true"
            :valueRange="{ min: 0 }"
            :allowNegative="false" />
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col md:w-1/6 w-full mt-0">
          <vs-input type="number" @change="calculateUnity(3)" class="w-full mt-0" label="" v-model="internalServiceOrder.quantity_3" />
        </div>
        <div class="vx-col md:w-1/2 w-full mt-0">
          <vs-input class="w-full mt-0" label="" v-model="internalServiceOrder.description_3" />
        </div>
        <div class="vx-col md:w-1/6 w-full mt-0">
          <currency-input class="w-full vs-inputx vs-input--input normal hasValue"
            v-model="internalServiceOrder.unit_value_3"
            @input="calculateUnity(3)"
            :currency="{prefix: 'R$ '}"
            :valueAsInteger="false"
            :distractionFree="false"
            :precision="2"
            :autoDecimalMode="true"
            :valueRange="{ min: 0 }"
            :allowNegative="false" />
        </div>
        <div class="vx-col md:w-1/6 w-full mt-0">
          <currency-input class="w-full vs-inputx vs-input--input normal hasValue"
            v-model="internalServiceOrder.unit_total_3" disabled
            :currency="{prefix: 'R$ '}"
            :valueAsInteger="false"
            :distractionFree="false"
            :precision="2"
            :autoDecimalMode="true"
            :valueRange="{ min: 0 }"
            :allowNegative="false" />
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col md:w-1/6 w-full mt-0">
          <vs-input type="number" @change="calculateUnity(4)" class="w-full mt-0" label="" v-model="internalServiceOrder.quantity_4" />
        </div>
        <div class="vx-col md:w-1/2 w-full mt-0">
          <vs-input class="w-full mt-0" label="" v-model="internalServiceOrder.description_4" />
        </div>
        <div class="vx-col md:w-1/6 w-full mt-0">
          <currency-input class="w-full vs-inputx vs-input--input normal hasValue"
            v-model="internalServiceOrder.unit_value_4"
            @input="calculateUnity(4)"
            :currency="{prefix: 'R$ '}"
            :valueAsInteger="false"
            :distractionFree="false"
            :precision="2"
            :autoDecimalMode="true"
            :valueRange="{ min: 0 }"
            :allowNegative="false" />
        </div>
        <div class="vx-col md:w-1/6 w-full mt-0">
          <currency-input class="w-full vs-inputx vs-input--input normal hasValue"
            v-model="internalServiceOrder.unit_total_4" disabled
            :currency="{prefix: 'R$ '}"
            :valueAsInteger="false"
            :distractionFree="false"
            :precision="2"
            :autoDecimalMode="true"
            :valueRange="{ min: 0 }"
            :allowNegative="false" />
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col md:w-1/6 w-full mt-0">
          <vs-input type="number" @change="calculateUnity(5)" class="w-full mt-0" label="" v-model="internalServiceOrder.quantity_5" />
        </div>
        <div class="vx-col md:w-1/2 w-full mt-0">
          <vs-input class="w-full mt-0" label="" v-model="internalServiceOrder.description_5" />
        </div>
        <div class="vx-col md:w-1/6 w-full mt-0">
          <currency-input class="w-full vs-inputx vs-input--input normal hasValue"
            v-model="internalServiceOrder.unit_value_5"
            @input="calculateUnity(5)"
            :currency="{prefix: 'R$ '}"
            :valueAsInteger="false"
            :distractionFree="false"
            :precision="2"
            :autoDecimalMode="true"
            :valueRange="{ min: 0 }"
            :allowNegative="false" />
        </div>
        <div class="vx-col md:w-1/6 w-full mt-0">
          <currency-input class="w-full vs-inputx vs-input--input normal hasValue"
            v-model="internalServiceOrder.unit_total_5" disabled
            :currency="{prefix: 'R$ '}"
            :valueAsInteger="false"
            :distractionFree="false"
            :precision="2"
            :autoDecimalMode="true"
            :valueRange="{ min: 0 }"
            :allowNegative="false" />
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col md:w-1/6 w-full mt-0">

        </div>
        <div class="vx-col md:w-1/2 w-full mt-0">

        </div>
        <div class="vx-col md:w-1/6 w-full mt-0">

        </div>
        <div class="vx-col md:w-1/6 w-full mt-0">
          <currency-input class="w-full vs-inputx vs-input--input normal hasValue"
            v-model="internalServiceOrder.total" disabled
            :currency="{prefix: 'R$ '}"
            :valueAsInteger="false"
            :distractionFree="false"
            :precision="2"
            :autoDecimalMode="true"
            :valueRange="{ min: 0 }"
            :allowNegative="false" />
        </div>
      </div>
      <vs-divider></vs-divider>

      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-4 flex flex-wrap items-center justify-end">
          <vs-button class="ml-auto mt-2" @click="save" :disabled="!validateForm">Cadastrar</vs-button>
          <vs-button class="ml-4 mt-2" type="border" color="secondary" @click="cancel">Cancelar</vs-button>
          </div>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import { CurrencyInput } from 'vue-currency-input'

import moduleEmployee from '@/store/employee/moduleEmployee.js'

export default {
  name: 'new-medical-modal',

  components: {
    vSelect,
    CurrencyInput
  },
  props: {
    show: {
      Boolean, default: false
    },
    title: {
      String, default: 'Diálogo'
    },
    actionButtonText: {
      String, default: 'Deletar'
    },
    actionButtonColor: {
      String, default: 'danger'
    }
  },

  computed: {
    employees () {
      return this.$store.getters['employee/forSelect']
    },
    validateForm () {
      return !this.errors.any()
    }
  },

  data () {
    return {
      localShow: false,
      internalServiceOrder: {
        employee_id: null,

        quantity_1: 0,
        description_1: '',
        unit_value_1: 0,
        unit_total_1: 0,

        quantity_2: 0,
        description_2: '',
        unit_value_2: 0,
        unit_total_2: 0,

        quantity_3: 0,
        description_3: '',
        unit_value_3: 0,
        unit_total_3: 0,

        quantity_4: 0,
        description_4: '',
        unit_value_4: 0,
        unit_total_4: 0,

        quantity_5: 0,
        description_5: '',
        unit_value_5: 0,
        unit_total_5: 0,

        total: 0
      }
    }
  },

  watch: {
    show (val) {
      this.localShow = val
    },
    localShow (val) {
      if (!val) {
        this.internalServiceOrder = {
          employee_id: null,

          quantity_1: 0,
          description_1: '',
          unit_value_1: 0,
          unit_total_1: 0,

          quantity_2: 0,
          description_2: '',
          unit_value_2: 0,
          unit_total_2: 0,

          quantity_3: 0,
          description_3: '',
          unit_value_3: 0,
          unit_total_3: 0,

          quantity_4: 0,
          description_4: '',
          unit_value_4: 0,
          unit_total_4: 0,

          quantity_5: 0,
          description_5: '',
          unit_value_5: 0,
          unit_total_5: 0,

          total: 0
        }
        this.$emit('cancel')
      }
    }
  },
  methods: {
    action () {
      this.$emit('action')
    },
    cancel () {
      this.$emit('cancel')
    },
    calculateUnity (x) {
      console.log(x)
      if (x === 1) this.internalServiceOrder.unit_total_1 = (this.internalServiceOrder.unit_value_1 * this.internalServiceOrder.quantity_1)
      if (x === 2) this.internalServiceOrder.unit_total_2 = (this.internalServiceOrder.unit_value_2 * this.internalServiceOrder.quantity_2)
      if (x === 3) this.internalServiceOrder.unit_total_3 = (this.internalServiceOrder.unit_value_3 * this.internalServiceOrder.quantity_3)
      if (x === 4) this.internalServiceOrder.unit_total_4 = (this.internalServiceOrder.unit_value_4 * this.internalServiceOrder.quantity_4)
      if (x === 5) this.internalServiceOrder.unit_total_5 = (this.internalServiceOrder.unit_value_5 * this.internalServiceOrder.quantity_5)
      this.calculateTotal()
    },
    calculateTotal () {
      this.internalServiceOrder.total = (this.internalServiceOrder.unit_total_1 + this.internalServiceOrder.unit_total_2 + this.internalServiceOrder.unit_total_3 + this.internalServiceOrder.unit_total_4 + this.internalServiceOrder.unit_total_5)
    },
    async save () {
      try {
        this.$vs.loading()
        const resp = await this.$store.dispatch('internalServiceOrder/store', this.internalServiceOrder)
        this.$vs.loading.close()
        this.action()
        this.$emit('print', { data: { id: resp.data.id }})
        this.$vs.notify({
          time: 5000,
          title: 'SUCESSO',
          text: 'Dados Salvos.',
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-check'
        })
        this.localShow = false
      } catch (error) {
        this.$vs.loading.close()
        if (error.response.status === 412) {
          this.$vs.notify({
            time: 5000,
            title: 'AVISO',
            text: error.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
        } else {
          this.$vs.notify({
            time: 5000,
            title: 'Erro',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      }
    }
  },
  created () {
    if (!moduleEmployee.isRegistered) {
      this.$store.registerModule('employee', moduleEmployee)
      moduleEmployee.isRegistered = true
    }
    this.$store.dispatch('employee/fetchAll').then(() => {}).catch(err => { console.error(err) })
  }
}
</script>

<style>
  hr {
    margin-top: 2px;
    border: none;
    height: 1px;
    /* Set the hr color */
    color: rgb(204, 204, 204); /* old IE */
    background-color: rgb(204, 204, 204); /* Modern Browsers */
  }

  .popup70 .vs-popup {
    width: 70% !important;
  }

  .vue_select_drop_size_200 .vs__dropdown-menu {
    max-height: 200px;
  }
</style>
